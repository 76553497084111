<template>
  <div class="row card-panel red accent-2 white-text">
    <p class="flow-text">Crap, we have a problem here, but don't you worry! Our cyber pikas are working on it!</p>
  </div>
</template>

<script>
export default {
name: "Error"
}
</script>

<style scoped>

</style>